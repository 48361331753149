import { Img } from 'component/FrameL';
import React, { useState, useEffect } from 'react';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { Link, withRouter, useLocation } from 'react-router-dom';
import Logo from 'assets/LakehouseInn_LogoBLK.png'

import './style.css';

function DesktopNavbar({ CoreData }) {
  const { hotelLogo, bookingEngineUrl } = CoreData;
  const [isOpen, setIsOpen] = useState(true);
  const handleScrollClose = () => {
    if (window.scrollY < 100) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollClose, true);
    return () => {
      window.removeEventListener('scroll', handleScrollClose, true);
    }
  }, []);


  const { pathname } = useLocation();
  return (<>
    <div className={`navbar__bg-container bg-gradient-to-b from-white flex justify-between ${isOpen ? 'visible' : ''}`}>
      <div className='relative w-52 self-start text-center' style={{display:"block"}}>
        <Link className={pathname === '/' ? 'active' : undefined} style={{display:'block',width:'inherit'}} to="/">
          <div className='text-sm'>Lee, Massachusetts</div>
          <img src={Logo} alt="" className=' h-15' />
        </Link>
      </div>
      <ul className="navbar__content h-12 text-white  uppercase flex items-center text-center" role="navigation" >

        <li>
          <Link className={pathname === '/rooms' ? 'active' : undefined} to="/rooms">
            Rooms
          </Link>
        </li>
        <li>
          <Link className={pathname === '/amenities' ? 'active' : undefined} to="/amenities">
            Complimentaries
          </Link>
        </li>
        <li>
          <Link className={pathname === '/dining' ? 'active' : undefined} to="/dining">
            Breakfast
          </Link>
        </li>
        <li>
          <Link className={pathname === '/insiders-guide' ? 'active' : undefined} to="/insiders-guide">
            Insider's Guide
          </Link>
        </li>
        <li>
          <Link className={pathname === '/gallery' ? 'active' : undefined} to="/gallery">
            Gallery
          </Link>
        </li>
        {/* <li>
          <Link className={pathname === '/groups' ? 'active' : undefined} to="/groups">
            Groups
          </Link>
        </li> */}
        <li>
          <Link className={pathname === '/press' ? 'active' : undefined} to="/press">
            Press
          </Link>
        </li>
      </ul>
      <div className={`mr-8 z-10 relative mt-3`}>
      <div className='absolute right-[-30px] top-[-22px] w-52'>Guests 21 And Over Only</div>
        <a
          href={bookingEngineUrl || process.env.REACT_APP_DEFAULT_BOOK_NOW}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Book Now Link"
          className="bg-white px-4 py-2 text-sans-serif uppercase no-underline block bg-blue-dark text-white">
          Book Now
        </a>
      </div>
    </div>
  </>
  );
}


function MobileNavbar({ CoreData }) {
  const { hotelLogo, bookingEngineUrl } = CoreData;
  const [isOpen, setIsOpen] = useState(false);
  return (<div className="fixed z-20 text-white">
    <div className='fixed right-0 top-0 w-52'>Guests 21 And Over Only</div>
    <div className={`fixed mt-4 mr-4 top-2 right-0 cluster-l text-black `}>
      <a
        className='no-underline uppercase btn bg-blue-light text-sans-serif'
        target="_blank"
        rel="noopener noreferrer"
        href={bookingEngineUrl || process.env.REACT_APP_DEFAULT_BOOK_NOW}>
        Book Now
      </a>
      <button className={`p-1 bg-blue-light`} onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="text-4xl"></IoMdMenu>
      </button>
    </div>
    <div className={`text-2xl uppercase text-sans-serif items-center navbar-bg-color stack-l mobile-nav__container ${isOpen ? 'visible' : ''}`} style={{ '--justify': 'center' }}>
      <button className='absolute top-0 right-0'>
        <IoMdClose className="icon m-4" onClick={() => {
          setIsOpen(false);
        }} />
      </button>

      <Link to="/" className='max-w-xs' onClick={() => {
        setIsOpen(false);
      }}>
        <Img Image={hotelLogo}></Img>
      </Link>
      <Link to="/rooms" onClick={() => {
        setIsOpen(false);
      }}>
        Rooms
      </Link>
      <Link to="/amenities" onClick={() => {
        setIsOpen(false);
      }}>
        Complimentaries
      </Link>
      <Link to="/dining" onClick={() => {
        setIsOpen(false);
      }}>
        Breakfast
      </Link>
      <Link to="/insiders-guide" onClick={() => {
        setIsOpen(false);
      }}>
        Insider's Guide
      </Link>
      <Link to="/gallery" onClick={() => {
        setIsOpen(false);
      }}>
        Gallery
      </Link>
      {/* <Link to="/groups" onClick={() => {
        setIsOpen(false);
      }}>
        Groups
      </Link> */}
      <Link to="/press" onClick={() => {
        setIsOpen(false);
      }}>
        Press
      </Link>
    </div>
  </div>
  );
}

const NavBar = ({ CoreData }) => {
  return (
    <React.Fragment>
      <div className="hide-on-lg">
        <DesktopNavbar CoreData={CoreData} />
      </div>
      <div className="block lg:hidden">
        <MobileNavbar CoreData={CoreData} />
      </div>
    </React.Fragment>);
}

export default withRouter(NavBar);